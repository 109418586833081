const loginResponse = ( response ) => {
    return {
        type: 'LOGIN_RESPONSE',
        payload: response
    }
}

const aadhaarNo = ( response ) => {
    return {
        type: 'AADHAAR',
        payload: response
    }
}
const DocumentDisplay = ( response ) => {
    return {
        type: 'Document',
        payload: response
    }
}
const FetchBuisnessAddressCall = ( response ) => {
    return {
        type: 'FetchBuisnessAddress',
        payload: response
    }
}

export {loginResponse,DocumentDisplay,aadhaarNo,FetchBuisnessAddressCall};


