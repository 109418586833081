const initialState = {
    loginResponse: {},
    DocumentFetch: {},
    FetchBuisnessAddress:{},
}


const loanReducer = ( state = initialState, action ) => {

    switch ( action.type ) {
        case "LOGIN_RESPONSE":
            return {
                ...state,
                loginResponse: action.payload
            };

            case "Document":
                return {
                    ...state,
                    DocumentFetch: action.payload,
                };
            case "FetchBuisnessAddress":
                return {
                    ...state,
                    FetchBuisnessAddress: action.payload,
                };

                case "AADHAAR":
                    return {
                        ...state,
                        aadhaarNumber: action.payload,
                    };

        default:
            return state;

    }

}

export default loanReducer; 