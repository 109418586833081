import React, { useEffect, useState, useRef } from "react";
import ArthaClearButton from "../../fields/ArthaClearButton";
import ArthaContinueButton from "../../fields/ArthaContinueButton";
import { Navigate, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { handleSignOut } from "./SessionExpiredApi";
import LoadingSpinner from "../../fields/spinner";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  propertyLoanFirstFeePaymentAPI, propertyLoanSendPaymentLinkAPI,propertyLoanChangeApplicationStatusAPI
} from "../../actioncreators/actioncreators";
import { toast } from "react-toastify";
export default function SecondFeePayment({changeStatus,changeApplicantStatus}) {
  const navigate = useNavigate();
  // Default values or values from location.state
  const location = useLocation();
  const [fee, setFee] = useState();
  const [totalFees, setTotalFees] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [errorDisplayed, setErrorDisplayed] = useState(false);
  const [statusCode, setStatusCode] = useState(null); // Track the status code

  const errorDisplayedRef = useRef(false); // Ref to keep track of errorDisplayed
  const handleApiError = (error) => {
    if (errorDisplayedRef.current) return; // Do nothing if an error has already been displayed

    setErrorDisplayed(true); // Set the state to true
    errorDisplayedRef.current = true; // Set the ref to true

    if (error.response && error.response.status === 401) {
      handleSignOut();
      toast.error(
        "Your session has expired. You will be redirected to the Login Page."
      );
      navigate("/");
    } else if (error.response && error.response.status === 429) {
      toast.error(
        "Too Many Requests: You have exceeded the rate limit. Please try again later."
      );
    } else {
      toast.error(
        "There appears to be a technical issue connecting to our servers. Could you please try again later."
      );
    }

    // Reset the error flag after a certain time
    setTimeout(() => {
      setErrorDisplayed(false);
      errorDisplayedRef.current = false;
    }, 2000); // 2 seconds
  };

  const handleSendLinkClick = async () => {
    const data = {
      fee_stage: "Initial",
      fee_type: "Login"
    }

    try {
      const response = await propertyLoanSendPaymentLinkAPI(data);
      if (response?.businessStatusCode === 2) {
        setIsModalOpen(true);
      } else {
        setIsModalOpen(false);
      }
    }
    catch (error) {
      handleApiError(error);
      console.error("Error fetching loan data:", error);
    } finally {
      setIsLoading(false);
      setIsButtonClicked(false);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  
  const handleBack = () => {
    // changeApplicantStatus("");
    changeStatus("APPLICANT_DETAILS");
  };

  const handleContinue = () => {
    // navigate('/home')
    changeStatus("PROPERTY_DETAILS")
    changeApplicantStatus("PropertySummary");
  };

  const handleContinue1 = async () => {
  
    try {
      const response = await propertyLoanChangeApplicationStatusAPI();
      if (response?.data?.businessStatusCode === 2) {
        changeStatus("APPLICANT_DETAILS")
      } else {
        toast.info(response.data.message);
      }
    } catch (error) {
      handleApiError(error);
      console.error("Error fetching loan data:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const payload = {
        fee_stage: "Initial",
        fee_type: "Login",
      };

      try {
        const response = await propertyLoanFirstFeePaymentAPI(payload);
        setFee(response?.feeAmount);
        setTotalFees(response?.totalFee);
        setStatusCode(response?.businessStatusCode);
      } catch (error) {
        handleApiError(error);
        console.error('Error fetching fee data:', error);
      }
    };

    fetchData();
  }, []);

//   if (statusCode === 2.1) {
//     return (
//       <div className="row bg-gray-100 p-4 bg-white rounded-lg shadow-md">
//           <div className="p-6 text-center">
//             <h4 className="text-lg text-gray-700 mb-2">
//               Thank you for showing interest in our loan products.
//             </h4>
//             <h4 className="text-xl text-gray-900 mb-6" style={{ fontWeight: "bold" }}>
//               The first installment fee has already been paid.
//             </h4>

//             <div className="flex justify-center mb-8">
//               <div className="w-40 h-40 bg-green-100 rounded-full flex items-center justify-center">
//                 <CheckCircleIcon style={{ fontSize: "150px", color: "green" }} />
//               </div>
//             </div>
//           </div>
//         <div className="row">
//           <div className="col-6 text-end">
//             <ArthaClearButton type="button" onClick={handleBack}>
//               Back
//             </ArthaClearButton>
//           </div>
//           <div className="col-6 text-start">
//             <ArthaContinueButton
//               type="button"
//               onClick={handleContinue1}
//               style={{
//                 opacity: isButtonClicked ? 0.39 : 1,
//                 pointerEvents: isButtonClicked ? "none" : "auto",
//               }}
//             >
//               {isLoading ? (
//                 <LoadingSpinner style={{ pointerEvents: "none" }} />
//               ) : (
//                 "Continue"
//               )}
//             </ArthaContinueButton>
//           </div>
//         </div>
//       </div>
//     );
//   }

  return (
    <>
      <Dialog
        open={isModalOpen}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleCloseModal();
          }
        }}
        style={{ textAlign: "center" }}
      >
        <Button
          onClick={handleCloseModal}
          style={{
            color: "black",
            background: "white",
            fontWeight: "bold",
            position: "absolute",
            top: 0,
            right: 0,
          }}
        >
          X
        </Button>
        <DialogContent>
          <Typography className="fw-bold mt-3 p-2" style={{ color: "green" }}>
            Fee-payment link is successfully sent to your <br /> registered
            email Id / mobile number.
          </Typography>
        </DialogContent>
      </Dialog>

      <>
      <div className="row bg-white shadow-md ">
        <div className="col-12 col-md-1"></div>
          <div className="border p-4 col-12 col-md-10 text-center">
            <h5>
            Thank you for choosing our loan products!
            </h5>
            <div className="mt-5" >
              <p className="text-start text-dark fw-medium">
                Your total login fee is <strong>₹{" "}
                {totalFees?.toLocaleString("en-IN")}.00.</strong> You need to pay the first installment of <strong> <br /> ₹{" "}
                {fee?.toLocaleString("en-IN")}.00 </strong>now. The remaining amount will be collected later.
              </p>
            </div>

            <div className="mt-5">
            {/* <p className="mt-4 text-start text-dark fw-medium">
              Please click on 'Send Payment Link' to send a fee-payment link to your
              registered email Id / mobile number.
            </p> */}
            </div>
            {/* <div className="text-end mt-4">
              <ArthaContinueButton
                type="button"
                onClick={handleSendLinkClick}
                style={{
                  opacity: isButtonClicked ? 0.39 : 1,
                  pointerEvents: isButtonClicked ? "none" : "auto",
                  fontSize: "12px",
                  color: "white",
                  width: "130px",
                  backgroundColor: "#10215f",
                  height: "40px",
                }}
              >
                {isLoading ? (
                  <LoadingSpinner style={{ pointerEvents: "none" }} />
                ) : (
                  "Send Payment Link"
                )}
              </ArthaContinueButton>
            </div> */}

            <div className="row mt-4">
              <div className="col-12 col-md-6 mt-5 text-end">
                <ArthaClearButton type="button" onClick={handleBack}>
                  Back
                </ArthaClearButton>
              </div>
              <div className="col-12 col-md-6 mt-5 text-start">
                <ArthaContinueButton
                  type="button"
                  onClick={handleContinue}
                  style={{
                    opacity: isButtonClicked ? 0.39 : 1,
                    pointerEvents: isButtonClicked ? "none" : "auto",
                  }}
                >
                  {isLoading ? (
                    <LoadingSpinner style={{ pointerEvents: "none" }} />
                  ) : (
                    "Continue"
                  )}
                </ArthaContinueButton>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-1"></div>
        </div>
      </> 
    </>
  );
}
