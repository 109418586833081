import React, { useState, useRef, useEffect } from "react";
import { MdOutlineFileUpload } from "react-icons/md";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import ArthaClearButton from "../../fields/ArthaClearButton";
import ArthaContinueButton from "../../fields/ArthaContinueButton";
import Cancel from "./images/Cancel_1.png";
import { toast } from "react-toastify";

const UploadBankStatementPopup = ({
  open,
  handleClose,
  bank,
  fileNames,
  setFileNames,
}) => {
  const [fileInfo, setFileInfo] = useState([]);
  const [currentFile, setCurrentFile] = useState();
  const [markedForDeletion, setMarkedForDeletion] = useState([]);
  const hiddenFileInput = useRef(null);

  useEffect(() => {
    if (open) {
      setMarkedForDeletion([]);
      setFileInfo([]);
      setCurrentFile(null);
    }
  }, [open]);

  const handleFileChange = (e) => {
    const selectedFiles = e.target.files;
    if (selectedFiles.length > 0) {
      if (fileInfo.length + selectedFiles.length > 6) {
        toast.error("You can only upload a maximum of 6 documents.");
        return;
      }

      const isEPDF = Array.from(selectedFiles).every(file => 
        file.type === 'application/pdf'
      );

      if (!isEPDF) {
        toast.error("Only ePDF documents are allowed.");
        return;
      }

      const newFiles = Array.from(selectedFiles).map((file) => ({
        file,
        password: ""
      }));

      setFileInfo(prevFileInfo => [...prevFileInfo, ...newFiles]);
      setCurrentFile(selectedFiles[0]);
    }
  };

  const handleDeleteFile1 = (file) => {
    setMarkedForDeletion(prev => [...prev, file]);
  };

  const handleDeleteFile = (index) => {
    setFileInfo(prevFileInfo => {
      const updatedFileInfo = [...prevFileInfo];
      updatedFileInfo.splice(index, 1);
      return updatedFileInfo;
    });
    if (fileInfo.length <= 1) {
      setCurrentFile(null);
    }
  };

  const handlePasswordChange = (index, value) => {
    setFileInfo(prevFileInfo => {
      const updatedFileInfo = [...prevFileInfo];
      updatedFileInfo[index].password = value;
      return updatedFileInfo;
    });
  };

  const handlePasswordEdit = (file, value) => {
    setFileNames(prevFileNames => {
      return prevFileNames.map(item => {
        if (item.id === bank.bankId) {
          return {
            ...item,
            files: item.files.map(f => 
              f.name === file.name ? { ...f, password: value } : f
            )
          };
        }
        return item;
      });
    });
  };

  function fileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = function (event) {
        const base64String = event.target.result.split(",")[1];
        resolve(base64String);
      };
      reader.onerror = function (error) {
        console.error("Error reading the file:", error);
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  }

  const handleSave = async () => {
    try {
      // Get current bank's files
      const currentBankFiles = fileNames.find(item => item.id === bank.bankId);
      
      // Filter out marked for deletion files
      const remainingFiles = currentBankFiles 
        ? currentBankFiles.files.filter(file => 
            !markedForDeletion.some(markedFile => markedFile.name === file.name)
          )
        : [];

      // Process new files
      const newFilesData = await Promise.all(
        fileInfo.map(async (file) => ({
          name: file.file.name,
          password: file.password,
          bankStatement: await fileToBase64(file.file),
        }))
      );

      // Combine remaining files with new files
      const updatedFiles = [...remainingFiles, ...newFilesData];

      // Update fileNames state in parent component
      setFileNames(prevFileNames => 
        prevFileNames.map(item => 
          item.id === bank.bankId
            ? { ...item, files: updatedFiles }
            : item
        )
      );

      // Reset local state
      setFileInfo([]);
      setCurrentFile(null);
      setMarkedForDeletion([]);

      // Close popup and pass the updated files
      handleClose(updatedFiles, bank.bankId);
    } catch (error) {
      console.error("Error processing files:", error);
      toast.error("Error processing files. Please try again.");
    }
  };

  const handleCancel = () => {
    setFileInfo([]);
    setCurrentFile(null);
    setMarkedForDeletion([]);
    handleClose([], bank.bankId);
  };

  const isFileMarkedForDeletion = (file) => {
    return markedForDeletion.some(markedFile => markedFile.name === file.name);
  };

  const currentBankFiles = fileNames.find(item => item.id === bank.bankId)?.files || [];
  const visibleFiles = currentBankFiles.filter(file => !isFileMarkedForDeletion(file));

  return (
    <Dialog open={open} onClose={handleCancel} maxWidth="lg">
      <DialogContent>
        <div className="row d-flex justify-content-center mb-4">
          <div className="col-12 text-center">
            {bank.bankName} Bank Statement From {bank.bankTimePeriodFirstMonth} To {bank.bankTimePeriodLastMonth}
          </div>
        </div>

        <div className="row d-flex justify-content-center">
          <div className="col-12 text-center">
            {visibleFiles.map((file, index) => (
              <div key={index} className="uploadadhar row p-0 m-0">
                <div className="uploaded-image-container col-12 d-flex">
                  <p className="doc-label col-2 align-items-top" style={{ fontSize: "12px", paddingTop: "7px" }}>
                    {`Document ${index + 1}`}
                  </p>
                  <div className="col-5 mb-1">
                    <span style={{ fontSize: "12px", wordWrap: "break-word" }}>
                      {file.name}
                    </span>
                  </div>
                  <div className="col-1">
                    <span
                      onClick={() => handleDeleteFile1(file)}
                      title="Delete"
                      style={{ width: "50px", fontSize: "12px", cursor: "pointer" }}
                    >
                      <img
                        src={Cancel}
                        style={{ width: "20px", justifyContent: "center" }}
                        alt="Cancel"
                      />
                    </span>
                  </div>
                  <div className="col-4">
                    <input
                      type="password"
                      placeholder={`Enter Document password ${index + 1}`}
                      value={file.password || ""}
                      onChange={(e) => handlePasswordEdit(file, e.target.value)}
                      style={{
                        width: "100%",
                        height: "33px",
                        padding: "6px 12px",
                        fontSize: "12px",
                        lineHeight: "1.5",
                        color: "#495057",
                        backgroundColor: "#fff",
                        border: "1px solid #ced4da",
                        borderRadius: "0.25rem",
                        boxShadow: "none",
                        transition: "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
                      }}
                    />
                  </div>
                </div>
                <hr style={{ height: '1px', borderWidth: '0', backgroundColor: 'rgba(0, 0, 0, 0.5)' }} />
              </div>
            ))}

            {fileInfo.map((file, index) => (
              <div key={`new-${index}`} className="uploadadhar row p-0 m-0">
                <div className="uploaded-image-container col-12 d-flex">
                  <p className="doc-label col-2 align-items-top" style={{ fontSize: "12px", paddingTop: "7px" }}>
                    {`Document ${visibleFiles.length + index + 1}`}
                  </p>
                  <div className="col-5 mb-1">
                    <span style={{ fontSize: "12px", wordWrap: "break-word" }}>
                      {file.file.name}
                    </span>
                  </div>
                  <div className="col-1">
                    <span
                      onClick={() => handleDeleteFile(index)}
                      title="Delete"
                      style={{ width: "50px", fontSize: "12px", cursor: "pointer" }}
                    >
                      <img
                        src={Cancel}
                        style={{ width: "20px", justifyContent: "center" }}
                        alt="Cancel"
                      />
                    </span>
                  </div>
                  <div className="col-4">
                    <input
                      type="password"
                      placeholder={`Enter Document password ${visibleFiles.length + index + 1}`}
                      value={file.password}
                      onChange={(e) => handlePasswordChange(index, e.target.value)}
                      style={{
                        width: "100%",
                        height: "33px",
                        padding: "6px 12px",
                        fontSize: "12px",
                        lineHeight: "1.5",
                        color: "#495057",
                        backgroundColor: "#fff",
                        border: "1px solid #ced4da",
                        borderRadius: "0.25rem",
                        boxShadow: "none",
                        transition: "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
                      }}
                    />
                  </div>
                </div>
                <hr style={{ height: '1px', borderWidth: '0', backgroundColor: 'rgba(0, 0, 0, 0.5)' }} />
              </div>
            ))}
          </div>
        </div>

        <div className="uploadadhar row">
          <span>
            <label htmlFor="fileInput" className="upload-label d-flex justify-content-center">
              <span className="upload-icon">
                <MdOutlineFileUpload />
              </span>
              <span className="mt-1">Upload</span>
            </label>
            <input
              ref={hiddenFileInput}
              type="file"
              id="fileInput"
              style={{ display: "none" }}
              onChange={handleFileChange}
              onClick={(e) => (e.target.value = null)}
            />
          </span>
        </div>

        <div className="row mt-4">
          <div className="col-6 d-flex justify-content-center">
            <ArthaClearButton type="button" onClick={handleCancel}>
              Cancel
            </ArthaClearButton>
          </div>
          <div className="col-6 d-flex justify-content-center">
            <ArthaContinueButton type="button" className="orange custom_btn" onClick={handleSave}>
              Continue
            </ArthaContinueButton>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default UploadBankStatementPopup;